export const APIURLS = {
  LOGIN: "/authenticate/login",
  VERIFY_TOKEN: "/verify-token",
  GRAPH: "https://graph.microsoft.com/v1.0/me",
  ROLES_LIST: "plan/getRoles",
  PLANNING_ELEMENTS: "plan/getPlan",
  FILTER_OPTIONS: "plan/getHierarchy",
  PRODUCT_HIERARCHY: "/api/getProductsHierarchy",
  PRODUCT_REFERENCEDATA: "/sellin/getCCMProductsReferenceData",
  PRODUCT_SELLINDATA: '/sellin/getCCMFillInData',
  PRODUCT_SALESOPSDATA: '/sellin/getSalesOpsRefernceAndSellIn',
  PRODUCT_SALESOPSDATA_NEW: '/sellin/getSalesOpsRefernceAndSellInNew',
  UPDATE_SELLINDATA: '/sellin/updateRsfPlan',
  PRODUCT_GROWTHDATA: '/sellin/getCCMGrowthPercentages',
  TERRITORY_PRODUCTDATA: '/sellin/territoryProduct',
  PRODUCT_REFERENCE_SELLOUTDATA: '/sellOut/getCCMSellOutRefernce',
  PRODUCT_FELLIN_SELLOUTDATA: '/sellOut/getCCMSellOutFillIn',
  PRODUCT_GROWTH_SELLOUTDATA: '/sellOut/getCCMSellOutGrowthPercentages',
  PRODUCT_UPDATE_SELLOUTDATA: '/sellOut/updateSelloutVolume',
  SELLOUT_SALESOPSDATA: 'sellOut/getSalesOpsSellout',
  SELLOUT_SELLOUTTOTALS: 'sellOut/getSelloutTotals',
  EMAIL_ONSUBMIT: 'api/emailOnSubmit',
  SELLOUT_TERRITORY_PRODUCTDATA: 'sellout/territoryProductSellout',
  FINANCE_REFERENCE_CCM_DATA: '/finance/getFinanceCCMRefernce',
  ANNUAL_FINANCE_REFERENCE_CCM_DATA: '/collection/getCollectionRef',
  FINANCE_SALESOPSDATA: 'finance/getFinanceSalesops',
  ANNUAL_COLLECTION_SALESOPSDATA: 'collection/getAnnualsSalesOpsCollection',
  FINANCE_CATEGORY_PRODUCT: 'finance/categoryTerritoryFinance',
  ANNUAL_ROLE_BASED_COLLECTION_DATA: 'collection/getRoleBasedCollectionData',
  FINANCE_PLAN_CCM_DATA: '/finance/getFinanaceFillInAPI',
  ANNUAL_FINANCE_PLAN_CCM_DATA: '/collection/getCollectionFillInData',
  ANNUAL_FINANCE_UPDATE_CCM_DATA: '/collection/updateCollectionPlan',
  ANNUAL_FINANCE_UPDATE_SALES_DATA: '/annualSales/updateSalesPlan',
  FINANCE_UPDATE_CCM_DATA: '/finance/updateCCMFinance',
  FINANCE_FINANCEKPIS: 'finance/getFinanceKpis',
  OPEX_CCMC_DATA: '/opex/getOpexSquad',
  OPEX_UPDATE_DATA: '/opex/updateOpexSquad',
  OPEX_UL_DATA: '/opex/getOpexUnit',
  OPEX_SUMMARY_DATA: '/opex/getOpexSummary',
  OPEX_COUNTRY_DATA: '/opex/getOpexCountry',
  LAST_UPDATE_TIMESTAMP: 'api/getLastUpdatedTimestamps',
  ADD_PLAN_LOG: 'api/addPlanningLog',
  GET_PROGRESS_BAR_REPORT: 'api/getProgressBarReport',
  GET_TEST_URL:'authenticate/token',
  ANNUAL_OPEX_CCMC_DATA: "",
  ANNUAL_OPEX_SQUAD_DATA: "/annualOpex/getSquadOpex",
  ANNUAL_OPEX_UNIT_DATA: "/annualOpex/getUnitOpex",
  ANNUAL_OPEX_UPDATE_DATA: '/annualOpex/updateOpex',
  ANNUAL_SALES_DATA:'annualSales/getSellInSellOutData',
  ANNUAL_ANAYSIS_DATA:'annualSales/getSellInSellOutAnalysisData',

  ANNUAL_UPLOAD_EXCEL_S3:'annualSales/uploadToS3',
  ANNUAL_GET_UPLOAD_EXCEL_STATUS:'annualSales/getfileUploadStatus',
  ANNUAL_SAVE_DOWNLOAD_EXCEL:'annualSales/downloadExcelSave',
  ANNUAL_GET_DOWNLOAD_EXCEL_NAME_BY_CWID:'annualSales/getDownloadedFileByCWID',
  ANNUAL_COLLECTIONEXCELUPLOAD:'collection/uploadcollectionplan',
  ANNUAL_OPEX_COUNTRY_DATA: "/annualOpex/getCountryOpex",
};

